<template>
  <div>
    <div :hidden="hiddenLoader" id="loader" class="text-center">
      <b-icon-circle-fill animation="throb" font-scale="4"></b-icon-circle-fill>
    </div>

    <div>
      <div :hidden="hidden">
        <div class="d-flex justify-content-end">
          <b-button class="mr-2" variant="primary" @click="openAddSalaire()"
            >Payer un salaire</b-button
          >
        </div>

        <b-card no-body title="" class="mt-1">
          <div class="my-2 mx-2 row">
            <!-- search start -->
            <div class="mr-3 col-lg-3 col-md-6 mt-2 mt-lg-0">
              <b-form-input
                id="filter-input"
                v-model="valueSearch"
                type="search"
                placeholder="Recherche"
                @input="filterValue"
              >
              </b-form-input>
            </div>
            <!-- search end -->

            <!-- select personnels start -->
            <b-form-select
              v-model="valueMembre"
              value-field="name"
              text-field="name"
              class="col-lg-3 col-md-6 mt-2 mt-lg-0"
              @input="filterValue"
              :options="membreListe"
            >
              <template #first>
                <b-form-select-option
                  :value="null"
                  style="color: #b4b7bd; text-align: center"
                  >-- Membres --</b-form-select-option
                >
              </template>
            </b-form-select>
            <!-- select personnels end -->
          </div>

          <!-- table start -->
          <b-table
            id="my-table"
            responsive
            :fields="headers"
            :items="salaires"
            :per-page="perPage"
            :current-page="currentPage"
            :filter="filter"
            :filter-function="filterFunction"
            @filtered="onFiltered"
            show-empty
            class="border-bottom"
          >
            <template #cell(name)="data">
              <span>
                {{ data.item.name ? data.item.name : "----" }}
              </span>
            </template>

            <template #cell(paid_salary)="data">
              {{
                data.item.entree
                  ? $thousandSeparator(
                      data.item.entree - data.item.remboursemement
                    )
                  : $thousandSeparator(
                      data.item.sortie - data.item.remboursemement
                    )
              }}
            </template>

            <template #cell(actions)="data">
              <b-button
                :id="'button-3' + data.item.id"
                v-if="$hasFonction(user, 'Personnel')"
                size="sm"
                variant="flat-primary"
                class="btn-icon"
                @click="printItem(data.item)"
              >
                <feather-icon icon="FileTextIcon" />
              </b-button>
              <b-tooltip
                :target="'button-3' + data.item.id"
                title="Fiche de paie"
                variant="dark"
              ></b-tooltip>
            </template>

            <template #empty>
              <div class="text-center">
                <span class="text-nowrap">Aucune donnée existante</span>
              </div>
            </template>
            <template #emptyfiltered>
              <div class="text-center">
                <span class="text-nowrap"
                  >L'élément recherché n'est pas dans la liste</span
                >
              </div>
            </template>
          </b-table>
          <!-- table end -->

          <div class="my-2 mx-2 row">
            <!-- per-page end -->
            <div class="col">
              <b-form-select
                id="per-page-select"
                v-model="perPage"
                :options="pageOptions"
                style="width: 70px"
              ></b-form-select>
              <span class="text-nowrap ml-2" v-if="allRows != 0"> 1 - <span v-if="perPage >= allRows">{{ allRows }}</span> 
	                <span v-if="perPage < allRows"> {{perPage}} </span> sur {{ allRows }}
                  </span>
              <span class="text-nowrap ml-2" v-if="allRows == 0"> 0 éléments</span>
            </div>
            <!-- per-page end -->

            <!-- pagination start -->
            <b-pagination
              class="pagination"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              aria-controls="my-table"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
            <!-- pagination end -->
          </div>
        </b-card>

        <!-- modal edit start  -->
        <b-modal
          size="lg"
          id="modal-AddSalaire"
          v-model="modalAddSalaire"
          title="Effectuer un paiement salaire"
          @hidden="resetSalaire"
          hide-footer
          :ok-disabled="isOperating"
          centered
        >
          <!-- form start -->
          <b-form
            class="mt-1"
            ref="addForm"
            @submit.stop.prevent="handleSubmit"
          >
            <div class="row">
              <b-col>
                <b-form-group label="Personnel" label-for="membres">
                  <validation-provider
                    #default="{ errors }"
                    name="Personnel"
                    rules="required"
                  >
                    <model-list-select
                        :list="membreListe"
                        id="membres"
                        v-model="addSalaire.personnel"
                        option-text="name"
                        option-value="id"
                        @input="changePersonnel()"
                        :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Salaire" label-for="SALAIRE">
                  <b-button block variant="secondary">
                    {{ $thousandSeparator(personnel_salary) }}
                  </b-button>
                </b-form-group>
              </b-col>
            </div>

            <div class="row">
              <b-col>
                <b-form-group label="Présence au poste" label-for="date_debut">
                  <validation-provider
                    #default="{ errors }"
                    name="date_debut"
                    rules="required"
                  >
                    <date-picker
                      v-model="addSalaire.date"
                      type="month"
                      value-type="format"
                    ></date-picker>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col>
                <b-form-group label="Prime" label-for="Prime">
                  <validation-provider
                    #default="{ errors }"
                    name="Prime"
                    rules="required"
                  >
                    <cleave
                      id="number"
                      v-model="addSalaire.prime"
                      class="form-control"
                      :raw="false"
                      :options="numberFormat"
                      placeholder="0"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </div>

            <div v-if="this.addSalaire.personnel_debt > 0">
              <b-row>
                <b-col>
                  <b-alert class="mt-2" show variant="warning">
                    <div class="alert-body d-flex flex justify-space-between">
                      <span class="mr-auto"
                        >Existe t'il un remboursement ?
                      </span>
                      <b-form-checkbox
                        class="ml-auto"
                        switch
                        inline
                        value="1"
                        unchecked-value="0"
                        v-model="existRemboursement"
                        @change="rembourse"
                      >
                      </b-form-checkbox>
                    </div>
                  </b-alert>
                </b-col>
              </b-row>
              <div v-if="existRemboursement == '1'">
                <b-row v-for="(item, index) in dettesList" :key="index">
                  <b-form-group class="col" disabled>
                    <validation-provider #default="{ errors }" name="créancier">
                      <label for="creancier">Créancier</label>
                      <b-form-input
                        disabled
                        v-model="item.creancier"
                        :placeholder="
                          item.creancier ? item.creancier : 'caisse'
                        "
                        :state="errors.length > 0 ? false : null"
                      />
                    </validation-provider>
                  </b-form-group>

                  <b-form-group class="col">
                    <validation-provider
                      #default="{ errors }"
                      name="montant"
                      :rules="existRemboursement ? 'required' : ''"
                    >
                      <label for="montant">Montant</label>
                      <cleave
                        id="montant"
                        v-model="item.paye"
                        @input="checkAmount(item)"
                        class="form-control"
                        :raw="false"
                        placeholder="0"
                        :options="numberFormat"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group class="col">
                    <label for="">Reste</label>
                    <b-button block variant="secondary">
                      {{
                        $thousandSeparator(
                          item.reste - $thousandFormater(item.paye)
                        )
                      }}
                    </b-button>
                  </b-form-group>
                </b-row>
                <b-alert class="mt-2" show variant="info">
                  <div class="alert-body d-flex flex justify-space-between">
                    <span class="mr-auto text-uppercase">Salaire Net </span>
                    <b-button size="sm" class="ml-auto" variant="secondary">
                      {{ $thousandSeparator(resteSalaire) }}
                    </b-button>
                  </div>
                </b-alert>
              </div>
              <!-- <b-row>
                <b-col>
                  <label for="">Dette totale</label>
                  <b-button block variant="secondary">
                    {{ $thousandSeparator(this.addSalaire.personnel_debt) }}
                  </b-button>
                </b-col>
                <b-col>
                  <validation-provider
                    #default="{ errors }"
                    name="montant"
                    :rules="existRemboursement ? 'required' : ''"
                  >
                    <label for="">Montant à payer</label>
                    <b-form-input
                      id="montant"
                      type="number"
                      :disabled="!existRemboursement"
                      placeholder="Montant à payer"
                      v-model="addSalaire.montant_remboursement"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col>
                  <label for="">Total payé</label>
                  <b-button block variant="secondary">
                    {{ $thousandSeparator(resteSalaire) }}
                  </b-button>
                </b-col>
              </b-row> -->
            </div>

            <b-form-group class="mb-0">
              <b-button
                variant="outline-secondary"
                type="reset"
                class="mt-2 mr-1"
                @click="modalAddSalaire = false"
              >
                Annuler
              </b-button>

              <b-button
                v-if="etatButton"
                variant="primary"
                class="mt-2"
                :disabled="isOperating"
                @click="savePaid"
              >
                Enregistrer
              </b-button>

              <b-button v-else variant="primary" class="mt-2">
                <b-icon-arrow-clockwise
                  animation="spin"
                  font-scale="1"
                ></b-icon-arrow-clockwise>
                En cours
              </b-button>
            </b-form-group>
          </b-form>
        </b-modal>
      </div>

      <div :hidden="hiddenEmpty" class="text-center">
        <empty-list></empty-list>
        <b-button variant="primary" size="sm" @click="openAddSalaire()">
          <b-icon-plus></b-icon-plus>
          AJOUTER
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { ModelListSelect } from "vue-search-select";
import Cleave from "vue-cleave-component";
import EmptyList from "@core/components/empty-list/EmptyList.vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import vSelect from "vue-select";
import { json2excel, excel2json } from "js2excel";
import downloadExcel from "json2excel-js";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store/index";
import VueSweetalert2 from "vue-sweetalert2";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer, alpha, alphaDash } from "@validations";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import {
  BIconPlus,
  BCard,
  BAlert,
  BTable,
  BRow,
  BCol,
  BButton,
  BModal,
  BForm,
  BCardText,
  BFormInput,
  BFormGroup,
  BPagination,
  BFormSelect,
  BFormCheckbox,
  BTooltip,
  BInputGroupAppend,
  BInputGroup,
  BFormDatepicker,
  BIconCircleFill,
  VBTooltip,
  BFormSelectOption,
  BFormTextarea,
  BIconArrowClockwise,
  BIconFileEarmarkArrowDownFill,
  BFormInvalidFeedback,
} from "bootstrap-vue";

export default {
  components: {
    VueSweetalert2,
    DatePicker,
    VueGoodTable,
    Cleave,
    BCardCode,
    BPagination,
    BFormSelect,
    BFormCheckbox,
    EmptyList,
    BIconPlus,
    BRow,
    BCol,
    BCard,
    BButton,
    BModal,
    BForm,
    ModelListSelect,
    BAlert,
    BTooltip,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormGroup,
    vSelect,
    BCardText,
    BTable,
    BFormDatepicker,
    BIconCircleFill,
    BFormTextarea,
    BFormSelectOption,
    VBTooltip,
    BIconFileEarmarkArrowDownFill,
    BFormInvalidFeedback,
    BIconArrowClockwise,
    ValidationProvider,
    ValidationObserver,
    json2excel,
    excel2json,
    downloadExcel,
  },

  data() {
    return {
      dateFormatOptions: { month: "numeric", year: "numeric" },
      etatButton: true,
      hidden: true,
      hiddenEmpty: true,
      hiddenLoader: false,
      membresList: [],
      personnel_salary: 0,
      selectedMembre: null,
      isOperating: false,
      allRows: 0,
      modalAddSalaire: false,
      perPage: 10,
      currentPage: 1,
      filter: "",
      pageOptions: [],
      historiques: [],
      headers: [
        {
          label: "Date de paiement",
          align: "start",
          key: "created_at",
          sortable: true,
        },
        {
          label: "Personnel concerné",
          align: "start",
          key: "personnel.name",
          sortable: true,
        },
        { label: "Montant", key: "paid_salary" },
        { label: "Présence au poste", key: "pour_compte" },
        { label: "Actions", key: "actions", sortable: false },
      ],
      nameState: null,
      addSalaire: {
        prime: 0,
        membres: "",
        motif_conge: "",
        date_debut: "",
        nombre_jours: "",
      },
      membresModalErrors: null,
      motifModalErrors: null,
      valueSearch: null,
      valueMembre: null,
      numberFormat: {
        numeral: true,
        numeralThousandsGroupStyle: "thousand",
        delimiter: " ",
      },
      salaires: [],
      membreListe: [],
      date: new Date().toISOString().substr(0, 7),
      menu: false,
      dettesList: [],
      membres: [],
      existRemboursement: false,
      existSociete: false,
    };
  },

  computed: {
    totalRows() {
      this.pageOptions = [10, 25, 50, 100, { value: "10000000000000000000000000", text: "Tout" } ]
      return this.allRows;
    },
    ...mapState({
      user: (state) => state.user.auth,
    }),
    credit() {
      let a = this.addSalaire.personnel_debt;
      return a ? a : 0;
    },
    resteSalaire() {
      if (this.addSalaire.personnel) {
        let somme = 0;
        this.dettesList.forEach((element) => {
          somme += this.$thousandFormater(element.paye);
        });
        return (
          parseFloat(this.addSalaire.personnel_salary) -
          somme +
          this.$thousandFormater(this.addSalaire.prime)
        );
      } else {
        return 0;
      }
      // return reste_c && reste_c > 0 ? reste_c : 0;
    },
  },

  created() {
    this.salairesData();
    this.membresData();
  },

  mounted() {},

  methods: {
    filterValue(val) {
      this.filter = " ";
    },

    checkAmount(item) {
      // console.log(item);
      if (this.$thousandFormater(item.paye) > item.reste) {
        this.showToast(
          "Le montant remboursé ne peut être supérieur au montant dû",
          "danger"
        );
        item.paye = 0;
      }
    },

    onFiltered(filteredItems) {
        this.allRows =  filteredItems.length 
        this.currentPage = 1
      },

    filterFunction(row, DateFilter) {
      if (this.valueMembre != null && this.valueMembre != row.personnel.name) {
        return false;
      } else {
        if (
          this.valueSearch &&
          row.personnel.name
            .toLowerCase()
            .indexOf(this.valueSearch.toLowerCase()) == -1
        ) {
          return false;
        } else {
          return true;
        }
      }
    },

    membreFilter(value) {
      if (!this.membreFilterValue) {
        return true;
      }
      return value === this.membreFilterValue;
    },

    toDate(dateStr) {
      if (dateStr) {
        dateStr = dateStr.replace(/\//g, "-");
        var parts = dateStr.split("-");
        return this.datetoString(parts[1]) + " " + parts[0];
      }
    },

    datetoString(number) {
      // let numbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']
      let mois = [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
      ];
      return mois[parseInt(number - 1)];
    },

    changePersonnel() {
      //console.log(this.addSalaire.personnel)
      if (this.addSalaire.personnel) {
        let personnel = this.membreListe.find(
          ({ id }) => id == this.addSalaire.personnel
        );
        this.dettesList = [];
        this.addSalaire.personnel_salary = personnel.salary;
        this.personnel_salary = personnel.salary;
        this.addSalaire.personnel_debt = 0;
        this.credit;

        if (personnel.dettes.length) {
          personnel.dettes.forEach((element) => {
            if (element.reste > 0) {
              this.addSalaire.personnel_debt += element.reste;
              this.dettesList.push({
                pret: element.id,
                creancier: element.creancier,
                paye: 0,
                reste: element.reste,
              });
            }
          });
        }
      }
    },
    async salairesData() {
      this.loading = true;
      await this.$http.get("/gestion-personnel/salaires").then((response) => {
        this.salaires = response.data.data;
        this.loading = false;
        if (response.status) {
          this.hiddenLoader = true;
        }
        if (this.salaires.length) {
          this.hidden = false;
          this.hiddenEmpty = true;
        } else {
          this.hidden = true;
          this.hiddenEmpty = false;
        }

        this.allRows = this.salaires.length
      });
    },

    async membresData() {
      await this.$http.get("/gestion-personnel/personnels").then((response) => {
        this.membreListe = response.data.data;
      });
    },

    paidItem() {
      this.dialogPaid = true;
    },

    printItem(item) {
      let toPrint = {};
      let user = item.personnel;
      toPrint.date = item.pour_compte;
      toPrint.prime = item.prime;
      toPrint.user_name = user.name;
      toPrint.user_debt = user.debt ? user.debt : 0;
      toPrint.user_poste = user.work;
      toPrint.user_phone = user.phone;
      toPrint.user_salary = user.salary;
      toPrint.last_debt_paid = item.last_debt_paid;
      toPrint.debt_paid = user.debt_paid;
      toPrint.debt = user.debt ? user.debt : 0;
      toPrint.current_prime = item.prime;
      toPrint.hiring_date = user.hiring_date;

      // Dette total
      let totalDebt = toPrint.debt_paid + toPrint.debt;

      // Récuperer le reste à payer du précedant paiement
      let s = this.historiques.filter((sal) => sal == item.personnel.id);

      let montant_paye_jusque_la = 0;
      s.every((element) => {
        if (element.id == item.id) {
          return false;
        }
        if (element.last_debt_paid)
          montant_paye_jusque_la += element.last_debt_paid;
        return true;
      });

      toPrint.rap = totalDebt - (montant_paye_jusque_la + item.last_debt_paid);

      let routeData = this.$router.resolve({
        name: "personnel-print-fiche-de-paie",
        query: toPrint,
      });
      window.open(routeData.href, "_blank");
    },

    checkFormValidity() {
      const valid = this.$refs.addForm.checkValidity();
      this.nameState = valid;
      return valid;
    },

    formatAmount(amount) {
      amount = this.$thousandFormater(amount);
      return amount;
    },

    savePaid(bvModalEvt) {
      bvModalEvt.preventDefault();
      if (!this.checkFormValidity()) {
        return;
      }

      if (this.resteSalaire < 0) {
        this.showToast(
          "Le total des remboursements ne peut être supérieur au salaire perçu",
          "danger"
        );
        return;
      }

      this.isOperating = true;
      this.addSalaire.prime = this.$thousandFormater(this.addSalaire.prime);
      let dettesList = JSON.parse(JSON.stringify(this.dettesList));
      if (dettesList.length && this.existRemboursement == "1") {
        this.addSalaire.montant_remboursement = 0;
        dettesList.forEach((element) => {
          element.paye = this.formatAmount(element.paye);
          this.addSalaire.montant_remboursement += element.paye;
        });
        this.addSalaire.dettesList = dettesList;
      }
      this.addSalaire.type = "salaire";

      console.log("this.addSalaire", this.addSalaire);
      this.etatButton = false;

      this.$http
        .post("/gestion-personnel/historiques", this.addSalaire)
        .then((result) => {
          this.isOperating = false;
          if (result.data.success) {
            this.etatButton = true;
            this.historiques = result.data.data;
            this.modalAddSalaire = false;
            this.showToast("Paiement enregistré avec succès", "success");

            let rep = result.data.data;
            let user = result.data.data.personnel;

            let toPrint = {};

            toPrint.date = rep.pour_compte;
            toPrint.prime = rep.prime;
            toPrint.user_name = user.name;
            toPrint.debt = user.debt ? user.debt : 0;
            toPrint.user_poste = user.work;
            toPrint.user_phone = user.phone;
            toPrint.user_salary = user.salary;
            toPrint.last_debt_paid = rep.last_debt_paid;
            toPrint.debt_paid = user.debt_paid;
            toPrint.current_prime = user.current_prime;
            toPrint.hiring_date = user.hiring_date;

            let routeData = this.$router.resolve({
              name: "personnel-print-fiche-de-paie",
              query: toPrint,
            });
            window.open(routeData.href, "_blank");

            this.resetSalaire();
            this.membresData();
            this.salairesData();
          } else {
            this.etatButton = true;
            this.showToast(result.data.message, "danger");
          }
        })
        .catch((e) => {
          this.isOperating = false;
          this.etatButton = true;
          console.log("erreur d'ajout date:" + e);

          this.showToast(e.response.data.message, "danger");
          var err = e.response.data.errors;
        });

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
      });
    },

    rembourse() {
      if (!this.existRemboursement || this.reste < 0) {
        this.addSalaire.montant_remboursement = 0;
      }
    },

    resetSalaire() {
      this.addSalaire = {};
      this.modalAddSalaire = false;
    },

    showToast(titre, type) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: titre,
          icon: type === "success" ? "CheckSquareIcon" : "AlertCircleIcon",
          variant: type,
        },
      });
    },

    openAddSalaire() {
      this.modalAddSalaire = true;
    },
  },
};
</script>

<style lang="scss">
#loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
  padding-top: 15%;
  z-index: 1000;
}

.b-form-date-controls {
  margin-right: 38%;
}

.mx-datepicker {
  width: 100% !important;
}
</style>
